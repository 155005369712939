import React from "react"
import { graphql } from "gatsby"
import * as styles from "./band.module.css"
import Navbar from "../components/navbar"
import Card from "../components/card"

export default function Band({ data }) {
  let people = data.allMarkdownRemark.nodes

  return (
    <div className={styles.main}>
      <Navbar />

      <div className={styles.cards}>
        {people.map(person => (
          <Card person={person.frontmatter} key={person.id}/>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query Bandmembers {
    allMarkdownRemark(
      sort: { fields: frontmatter___lastname }
      filter: { fileAbsolutePath: { regex: "/content/members/" } }
    ) {
      nodes {
        frontmatter {
          instrument
          firstname
          lastname
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        html
        id
      }
    }
    file(relativePath: { eq: "IMG_3938.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
